import en from "./en"
import tr from "./tr"
import {enComponentDictionary, trComponentDictionary} from "@bis-react-comp/workwell-chatbot";

const translations: { en: Record<string, string>, tr: Record<string, string> } = {
    en: {
        ...en,
        ...enComponentDictionary
    },
    tr: {
        ...tr,
        ...trComponentDictionary
    },
};

export default translations;