export const Account = "Key.Account"
export const AccountSecurity = "Key.AccountSecurity"
export const Accuracy = "Key.Accuracy"
export const AccuracyRatio = "Key.AccuracyRatio"
export const ActivationMailSent = "Key.ActivationMailSent"
export const ActiveApiKeyListed = "Key.ActiveApiKeyListed"
export const AddClass = "Key.AddClass"
export const AddFileToUseLiveInterface = "Key.AddFileToUseLiveInterface"
export const AddLogo = "Key.AddLogo"
export const AddToSavedAddress = "Key.AddToSavedAddress"
export const Address = "Key.Address"
export const AddressIsSaved = "Key.AddressIsSaved"
export const AiDepartmentApiKey = "Key.AiDepartmentApiKey"
export const AiDepartmentApiKeyDeleteConfirmMessage = "Key.AiDepartmentApiKeyDeleteConfirmMessage"
export const AiDepartmentApiKeyRegenerateConfirmMessage = "Key.AiDepartmentApiKeyRegenerateConfirmMessage"
export const AiDepartmentApiKeyRegenerateWarningMessage = "Key.AiDepartmentApiKeyRegenerateWarningMessage"
export const All = "Key.All"
export const AllKeys = "Key.AllKeys"
export const AllServicesAllowed = "Key.AllServicesAllowed"
export const AllYouNeedIsAKey = "Key.AllYouNeedIsAKey"
export const AllowedServices = "Key.AllowedServices"
export const AlreadyAMember = "Key.AlreadyAMember"
export const Amount = "Key.Amount"
export const AngleCalculate = "Key.AngleCalculate"
export const AngleCalculateDescription = "Key.AngleCalculateDescription"
export const AngleRotate = "Key.AngleRotate"
export const AngleRotateDescription = "Key.AngleRotateDescription"
export const Api = "Key.Api"
export const ApiKey = "Key.ApiKey"
export const ApiKeyCreated = "Key.ApiKeyCreated"
export const ApiKeyListed = "Key.ApiKeyListed"
export const ApiKeyName = "Key.ApiKeyName"
export const ApiKeys = "Key.ApiKeys"
export const ApiReference = "Key.ApiReference"
export const ApiReferenceDescription = "Key.ApiReferenceDescription"
export const ApiResponse = "Key.ApiResponse"
export const ApiServices = "Key.ApiServices"
export const ApiServicesDocumentDescription = "Key.ApiServicesDocumentDescription"
export const ApiServicesExtractDescription = "Key.ApiServicesExtractDescription"
export const ApiServicesMetadataDescription = "Key.ApiServicesMetadataDescription"
export const ApiServicesOCRDescription = "Key.ApiServicesOCRDescription"
export const ApiServicesRotationDescription = "Key.ApiServicesRotationDescription"
export const ApiServicesSignatureDescription = "Key.ApiServicesSignatureDescription"
export const ApproveFile = "Key.ApproveFile"
export const ApproveWithExpressConsent = "Key.ApproveWithExpressConsent"
export const AreYouSure = "Key.AreYouSure"
export const ArkApiTermsMessage = "Key.ArkApiTermsMessage"
export const ArkApiTermsErrorMessage = "Key.ArkApiTermsErrorMessage"
export const Authentication = "Key.Authentication"
export const AuthenticationAddApiKey = "Key.AuthenticationAddApiKey"
export const Back = "Key.Back"
export const BaseModel = "Key.BaseModel"
export const BaselineAccuracy = "Key.BaselineAccuracy"
export const BestAccuracy = "Key.BestAccuracy"
export const Billing = "Key.Billing"
export const BillingDetails = "Key.BillingDetails"
export const BoxesCalculate = "Key.BoxesCalculate"
export const BoxesCalculateDescription = "Key.BoxesCalculateDescription"
export const BoxesDraw = "Key.BoxesDraw"
export const BoxesDrawDescription = "Key.BoxesDrawDescription"
export const BuiltPlan = "Key.BuiltPlan"
export const BuyNow = "Key.BuyNow"
export const Csv = "Key.Csv"
export const Cancel = "Key.Cancel"
export const CardDeclinedDueToTransactionProcessing = "Key.CardDeclinedDueToTransactionProcessing"
export const CardNumber = "Key.CardNumber"
export const CardholderName = "Key.CardholderName"
export const ChangeEmail = "Key.ChangeEmail"
export const ChangeMyPassword = "Key.ChangeMyPassword"
export const ChangePassword = "Key.ChangePassword"
export const CheckInternetConnection = "Key.CheckInternetConnection"
export const Checkout = "Key.Checkout"
export const City = "Key.City"
export const Classification = "Key.Classification"
export const ClassificationDescription = "Key.ClassificationDescription"
export const CleanImage = "Key.CleanImage"
export const CleanImageDescription = "Key.CleanImageDescription"
export const CleanPdf = "Key.CleanPdf"
export const CleanPdfDescription = "Key.CleanPdfDescription"
export const Clear = "Key.Clear"
export const ClearFilter = "Key.ClearFilter"
export const ClickBelowToTrainNewModel = "Key.ClickBelowToTrainNewModel"
export const ClickBelowTrainNewModel = "Key.ClickBelowTrainNewModel"
export const ClientId = "Key.ClientId"
export const CompanyName = "Key.CompanyName"
export const Compare = "Key.Compare"
export const CompareDescription = "Key.CompareDescription"
export const ComparePlans = "Key.ComparePlans"
export const ConfidenceLevel = "Key.ConfidenceLevel"
export const ConfidenceLevelTooltipMessage1 = "Key.ConfidenceLevelTooltipMessage1"
export const ConfidenceLevelTooltipMessage2 = "Key.ConfidenceLevelTooltipMessage2"
export const Confirm = "Key.Confirm"
export const ConfirmFile = "Key.ConfirmFile"
export const ConfirmPassword = "Key.ConfirmPassword"
export const ConfirmPayment = "Key.ConfirmPayment"
export const ContactUs = "Key.ContactUs"
export const ContactWithUs = "Key.ContactWithUs"
export const Continue = "Key.Continue"
export const ContractApproval = "Key.ContractApproval"
export const ContractKvkkApprovalMessage = "Key.ContractKvkkApprovalMessage"
export const Copied = "Key.Copied"
export const CopyApiKey = "Key.CopyApiKey"
export const CorporateInvoice = "Key.CorporateInvoice"
export const CouldNotLoadCaptchaMessage = "Key.CouldNotLoadCaptchaMessage"
export const CountDocument = "Key.CountDocument"
export const CountPages = "Key.CountPages"
export const CountServices = "Key.CountServices"
export const Country = "Key.Country"
export const Create = "Key.Create"
export const CreateApiKey = "Key.CreateApiKey"
export const CreateDate = "Key.CreateDate"
export const CreateNewKey = "Key.CreateNewKey"
export const CreateNewModel = "Key.CreateNewModel"
export const CreateNewPassword = "Key.CreateNewPassword"
export const CreateNewPasswordMessage = "Key.CreateNewPasswordMessage"
export const CreateOne = "Key.CreateOne"
export const CreateYourAccount = "Key.CreateYourAccount"
export const CreateYourFirstKey = "Key.CreateYourFirstKey"
export const CreditSpendings = "Key.CreditSpendings"
export const CreditSpendingsPerApiKey = "Key.CreditSpendingsPerApiKey"
export const CreditSpendingsPerService = "Key.CreditSpendingsPerService"
export const CreditSpendingsWithName = "Key.CreditSpendingsWithName"
export const CreditSystem = "Key.CreditSystem"
export const CreditUsage = "Key.CreditUsage"
export const CreditUsagePerPage = "Key.CreditUsagePerPage"
export const Credits = "Key.Credits"
export const CreditsAndBillingPage = "Key.CreditsAndBillingPage"
export const Crop = "Key.Crop"
export const CropDescription = "Key.CropDescription"
export const Curl = "Key.Curl"
export const CurrencyType = "Key.CurrencyType"
export const CurrentBillingCycle = "Key.CurrentBillingCycle"
export const CurrentPassword = "Key.CurrentPassword"
export const CurrentPasswordPlaceholder = "Key.CurrentPasswordPlaceholder"
export const Daily = "Key.Daily"
export const DateRange = "Key.DateRange"
export const Days = "Key.Days"
export const Delete = "Key.Delete"
export const DeleteAll = "Key.DeleteAll"
export const DeleteApiKey = "Key.DeleteApiKey"
export const DeleteChangesConfirmMessage = "Key.DeleteChangesConfirmMessage"
export const DeleteKey = "Key.DeleteKey"
export const DeleteLogo = "Key.DeleteLogo"
export const Description = "Key.Description"
export const DoYouHaveMoreQuestions = "Key.DoYouHaveMoreQuestions"
export const Document = "Key.Document"
export const DocumentTrain = "Key.DocumentTrain"
export const DocumentTrainDescription = "Key.DocumentTrainDescription"
export const Documentation = "Key.Documentation"
export const DocumentationFunctionsOfArkApi = "Key.DocumentationFunctionsOfArkApi"
export const DocumentationFunctionsOfArkApiDocumentP1 = "Key.DocumentationFunctionsOfArkApiDocumentP1"
export const DocumentationFunctionsOfArkApiExtractListHeader = "Key.DocumentationFunctionsOfArkApiExtractListHeader"
export const DocumentationFunctionsOfArkApiExtractListItem1 = "Key.DocumentationFunctionsOfArkApiExtractListItem1"
export const DocumentationFunctionsOfArkApiExtractListItem10 = "Key.DocumentationFunctionsOfArkApiExtractListItem10"
export const DocumentationFunctionsOfArkApiExtractListItem11 = "Key.DocumentationFunctionsOfArkApiExtractListItem11"
export const DocumentationFunctionsOfArkApiExtractListItem2 = "Key.DocumentationFunctionsOfArkApiExtractListItem2"
export const DocumentationFunctionsOfArkApiExtractListItem3 = "Key.DocumentationFunctionsOfArkApiExtractListItem3"
export const DocumentationFunctionsOfArkApiExtractListItem4 = "Key.DocumentationFunctionsOfArkApiExtractListItem4"
export const DocumentationFunctionsOfArkApiExtractListItem5 = "Key.DocumentationFunctionsOfArkApiExtractListItem5"
export const DocumentationFunctionsOfArkApiExtractListItem6 = "Key.DocumentationFunctionsOfArkApiExtractListItem6"
export const DocumentationFunctionsOfArkApiExtractListItem7 = "Key.DocumentationFunctionsOfArkApiExtractListItem7"
export const DocumentationFunctionsOfArkApiExtractListItem8 = "Key.DocumentationFunctionsOfArkApiExtractListItem8"
export const DocumentationFunctionsOfArkApiExtractListItem9 = "Key.DocumentationFunctionsOfArkApiExtractListItem9"
export const DocumentationFunctionsOfArkApiExtractP1 = "Key.DocumentationFunctionsOfArkApiExtractP1"
export const DocumentationFunctionsOfArkApiExtractP2 = "Key.DocumentationFunctionsOfArkApiExtractP2"
export const DocumentationFunctionsOfArkApiExtractP3 = "Key.DocumentationFunctionsOfArkApiExtractP3"
export const DocumentationFunctionsOfArkApiMetadata = "Key.DocumentationFunctionsOfArkApiMetadata"
export const DocumentationFunctionsOfArkApiMetadataListItem1Content = "Key.DocumentationFunctionsOfArkApiMetadataListItem1Content"
export const DocumentationFunctionsOfArkApiMetadataListItem1Label = "Key.DocumentationFunctionsOfArkApiMetadataListItem1Label"
export const DocumentationFunctionsOfArkApiMetadataListItem2Content = "Key.DocumentationFunctionsOfArkApiMetadataListItem2Content"
export const DocumentationFunctionsOfArkApiMetadataListItem2Label = "Key.DocumentationFunctionsOfArkApiMetadataListItem2Label"
export const DocumentationFunctionsOfArkApiMetadataListItem3Content = "Key.DocumentationFunctionsOfArkApiMetadataListItem3Content"
export const DocumentationFunctionsOfArkApiMetadataListItem3Label = "Key.DocumentationFunctionsOfArkApiMetadataListItem3Label"
export const DocumentationFunctionsOfArkApiMetadataListItem4Content = "Key.DocumentationFunctionsOfArkApiMetadataListItem4Content"
export const DocumentationFunctionsOfArkApiMetadataListItem4Label = "Key.DocumentationFunctionsOfArkApiMetadataListItem4Label"
export const DocumentationFunctionsOfArkApiMetadataListItem5Content = "Key.DocumentationFunctionsOfArkApiMetadataListItem5Content"
export const DocumentationFunctionsOfArkApiMetadataListItem5Label = "Key.DocumentationFunctionsOfArkApiMetadataListItem5Label"
export const DocumentationFunctionsOfArkApiMetadataP1 = "Key.DocumentationFunctionsOfArkApiMetadataP1"
export const DocumentationFunctionsOfArkApiOcr = "Key.DocumentationFunctionsOfArkApiOcr"
export const DocumentationFunctionsOfArkApiOcrP1 = "Key.DocumentationFunctionsOfArkApiOcrP1"
export const DocumentationFunctionsOfArkApiRotationP1 = "Key.DocumentationFunctionsOfArkApiRotationP1"
export const DocumentationFunctionsOfArkApiRotationP2 = "Key.DocumentationFunctionsOfArkApiRotationP2"
export const DocumentationFunctionsOfArkApiRotationP3 = "Key.DocumentationFunctionsOfArkApiRotationP3"
export const DocumentationFunctionsOfArkApiSignatureListItem1Content = "Key.DocumentationFunctionsOfArkApiSignatureListItem1Content"
export const DocumentationFunctionsOfArkApiSignatureListItem1Label = "Key.DocumentationFunctionsOfArkApiSignatureListItem1Label"
export const DocumentationFunctionsOfArkApiSignatureListItem2Content = "Key.DocumentationFunctionsOfArkApiSignatureListItem2Content"
export const DocumentationFunctionsOfArkApiSignatureListItem2Label = "Key.DocumentationFunctionsOfArkApiSignatureListItem2Label"
export const DocumentationFunctionsOfArkApiSignatureListItem3Content = "Key.DocumentationFunctionsOfArkApiSignatureListItem3Content"
export const DocumentationFunctionsOfArkApiSignatureListItem3Label = "Key.DocumentationFunctionsOfArkApiSignatureListItem3Label"
export const DocumentationFunctionsOfArkApiSignatureListItem4 = "Key.DocumentationFunctionsOfArkApiSignatureListItem4"
export const DocumentationFunctionsOfArkApiSignatureListItem5Content = "Key.DocumentationFunctionsOfArkApiSignatureListItem5Content"
export const DocumentationFunctionsOfArkApiSignatureListItem5Label = "Key.DocumentationFunctionsOfArkApiSignatureListItem5Label"
export const DocumentationFunctionsOfArkApiSignatureP1 = "Key.DocumentationFunctionsOfArkApiSignatureP1"
export const DocumentationFunctionsOfArkApiSignatureP2 = "Key.DocumentationFunctionsOfArkApiSignatureP2"
export const DocumentationGuideDescription = "Key.DocumentationGuideDescription"
export const DocumentationHowDoesCreditSystemWork = "Key.DocumentationHowDoesCreditSystemWork"
export const DocumentationHowDoesCreditSystemWorkP1 = "Key.DocumentationHowDoesCreditSystemWorkP1"
export const DocumentationHowDoesCreditSystemWorkP2 = "Key.DocumentationHowDoesCreditSystemWorkP2"
export const DocumentationWhatDoesArkApiOffer = "Key.DocumentationWhatDoesArkApiOffer"
export const DocumentationWhatDoesArkApiOfferP1 = "Key.DocumentationWhatDoesArkApiOfferP1"
export const DocumentationWhatDoesArkApiOfferP2 = "Key.DocumentationWhatDoesArkApiOfferP1"
export const DocumentationWhatDoesArkApiOfferP3 = "Key.DocumentationWhatDoesArkApiOfferP3"
export const DocumentationWhatIsArkApi = "Key.DocumentationWhatIsArkApi"
export const DocumentationWhatIsArkApiFor = "Key.DocumentationWhatIsArkApiFor"
export const DocumentationWhatIsArkApiForListHeader = "Key.DocumentationWhatIsArkApiForListHeader"
export const DocumentationWhatIsArkApiForListItem1 = "Key.DocumentationWhatIsArkApiForListItem1"
export const DocumentationWhatIsArkApiForListItem2 = "Key.DocumentationWhatIsArkApiForListItem2"
export const DocumentationWhatIsArkApiForListItem3 = "Key.DocumentationWhatIsArkApiForListItem3"
export const DocumentationWhatIsArkApiForListItem4 = "Key.DocumentationWhatIsArkApiForListItem4"
export const DocumentationWhatIsArkApiForP1 = "Key.DocumentationWhatIsArkApiForP1"
export const DocumentationWhatIsArkApiForP2 = "Key.DocumentationWhatIsArkApiForP2"
export const DocumentationWhatIsArkApiP1 = "Key.DocumentationWhatIsArkApiP1"
export const DocumentationWhatIsArkApiP2 = "Key.DocumentationWhatIsArkApiP2"
export const DocumentsUsed = "Key.DocumentsUsed"
export const Done = "Key.Done"
export const DontHaveAnAccount = "Key.DontHaveAnAccount"
export const DotNetCSharp = "Key.DotNetCSharp"
export const DragAndDropFilesHere = "Key.DragAndDropFilesHere"
export const Or = "Key.Or"
export const Supported = "Key.Supported"
export const ClickHereToUpload = "Key.ClickHereToUpload"
export const FileSizeMax = "Key.FileSizeMax"
export const EArchive = "Key.EArchive"
export const EInvoice = "Key.EInvoice"
export const Edit = "Key.Edit"
export const Email = "Key.Email"
export const EmailAddress = "Key.EmailAddress"
export const EmailConfirmedSuccessfully = "Key.EmailConfirmedSuccessfully"
export const En = "Key.En"
export const EnFlag = "Key.EnFlag"
export const EnLang = "Key.EnLang"
export const EnterYourEmailAddress = "Key.EnterYourEmailAddress"
export const EnumActive = "Key.Enum.Active"
export const EnumClassification = "Key.Enum.Classification"
export const EnumCreated = "Key.Enum.Created"
export const EnumCurrentBillingCycle = "Key.Enum.CurrentBillingCycle"
export const EnumDaily = "Key.Enum.Daily"
export const EnumDate = "Key.Enum.Date"
export const EnumDateRange = "Key.Enum.DateRange"
export const EnumDocument = "Key.Enum.Document"
export const EnumDocumentPdfCompress = "Key.Enum.DocumentPdfCompress"
export const EnumDocumentPdfDecrypt = "Key.Enum.DocumentPdfDecrypt"
export const EnumDocumentPdfEncrypt = "Key.Enum.DocumentPdfEncrypt"
export const EnumDocumentPdfExtractImage = "Key.Enum.DocumentPdfExtractImage"
export const EnumDocumentPdfMerge = "Key.Enum.DocumentPdfMerge"
export const EnumDocumentPdfSplit = "Key.Enum.DocumentPdfSplit"
export const EnumExpired = "Key.Enum.Expired"
export const EnumExtract = "Key.Enum.Extract"
export const EnumExtractImage = "Key.Enum.ExtractImage"
export const EnumExtractPdf = "Key.Enum.ExtractPdf"
export const EnumExtractText = "Key.Enum.ExtractText"
export const EnumExtractTiff = "Key.Enum.ExtractTiff"
export const EnumHigh = "Key.Enum.High"
export const EnumIdentity = "Key.Enum.Identity"
export const EnumInvoice = "Key.Enum.Invoice"
export const EnumLastBillingCycle = "Key.Enum.LastBillingCycle"
export const EnumLow = "Key.Enum.Low"
export const EnumMetadata = "Key.Enum.Metadata"
export const EnumMetadataCleanImage = "Key.Enum.MetadataCleanImage"
export const EnumMetadataCleanPdf = "Key.Enum.MetadataCleanPdf"
export const EnumMetadataExtractImage = "Key.Enum.MetadataExtractImage"
export const EnumMetadataExtractPdf = "Key.Enum.MetadataExtractPdf" 
export const EnumModelInProgress = "Key.Enum.InProgress"
export const EnumMonthly = "Key.Enum.Monthly"
export const EnumNer = "Key.Enum.Ner"
export const EnumNumeric = "Key.Enum.Numeric"
export const EnumOcr = "Key.Enum.Ocr"
export const EnumOcrImage = "Key.Enum.OcrImage"
export const EnumOcrPdf = "Key.Enum.OcrPdf"
export const EnumOcrTiff = "Key.Enum.OcrTiff"
export const EnumReadyForUse = "Key.Enum.ReadyForUse"
export const EnumRevoked = "Key.Enum.Revoked"
export const EnumRisky = "Key.Enum.Risky"
export const EnumRotation = "Key.Enum.Rotation"
export const EnumRotationAngleCalculate = "Key.Enum.RotationAngleCalculate"
export const EnumRotationAngleRotate = "Key.Enum.RotationAngleRotate"
export const EnumRotationBoxesCalculate = "Key.Enum.RotationBoxesCalculate"
export const EnumRotationBoxesDraw = "Key.Enum.RotationBoxesDraw"
export const EnumSignature = "Key.Enum.Signature"
export const EnumSignatureCompare = "Key.Enum.SignatureCompare"
export const EnumSignatureCrop = "Key.Enum.SignatureCrop"
export const EnumSignatureExtractCoordinate = "Key.Enum.SignatureExtractCoordinate"
export const EnumText = "Key.Enum.Text"
export const EnumTime = "Key.Enum.Time"
export const EnumTrainFromScratch = "Key.Enum.TrainFromScratch"
export const EnumTraining = "Key.Enum.Training"
export const EnumWeekly = "Key.Enum.Weekly"
export const ExpiryDate = "Key.ExpiryDate"
export const ExploreOurApiServices = "Key.ExploreOurApiServices"
export const Export = "Key.Export"
export const Extract = "Key.Extract"
export const ExtractCoordinate = "Key.ExtractCoordinate"
export const ExtractCoordinateDescription = "Key.ExtractCoordinateDescription"
export const ExtractImage = "Key.ExtractImage"
export const ExtractImageDescription = "Key.ExtractImageDescription"
export const ExtractImageInfo = "Key.ExtractImageInfo"
export const ExtractPdf = "Key.ExtractPdf"
export const ExtractPdfDescription = "Key.ExtractPdfDescription"
export const ExtractPdfInfo = "Key.ExtractPdfInfo"
export const ExtractTextInfo = "Key.ExtractTextInfo"
export const ExtractTiffInfo = "Key.ExtractTiffInfo"
export const ExtractedInformation = "Key.ExtractedInformation"
export const FieldMax = "Key.FieldMax"
export const FieldMin = "Key.FieldMin"
export const FieldNotValid = "Key.FieldNotValid"
export const FieldRequired = "Key.FieldRequired"
export const FileTypes = "Key.FileTypes"
export const Filters = "Key.Filters"
export const ForgotPassword = "Key.ForgotPassword"
export const FormData = "Key.FormData"
export const FormDataFormFile = "Key.FormDataFormFile"
export const FormDesc = "Key.FormDesc"
export const FormDesc2 = "Key.FormDesc2"
export const FreePage = "Key.FreePage"
export const GeneralErrorJson = "Key.GeneralErrorJson"
export const GetInTouch = "Key.GetInTouch"
export const GoToHomePage = "Key.GoToHomePage"
export const GoToPurchasing = "Key.GoToPurchasing"
export const GoToSignInPage = "Key.GoToSignInPage"
export const GotConfusedExpertsHereToHelp = "Key.GotConfusedExpertsHereToHelp"
export const Guide = "Key.Guide"
export const HideApiKey = "Key.HideApiKey"
export const HighAccuracy = "Key.HighAccuracy"
export const Home = "Key.Home"
export const HttpRequest = "Key.HttpRequest"
export const IHaveReadAndAccept = "Key.IHaveReadAndAccept"
export const IHaveReadAndApprovePreliminaryInformation = "Key.IHaveReadAndApprovePreliminaryInformation"
export const IdentityNumber = "Key.IdentityNumber"
export const IfYouNeedAnyHelpContactUs = "Key.IfYouNeedAnyHelpContactUs"
export const ImageInfo = "Key.ImageInfo"
export const ImageInfoDescription = "Key.ImageInfoDescription"
export const Inactive = "Key.Inactive"
export const Individual = "Key.Individual"
export const InsufficientCreditJSON = "Key.InsufficientCreditJSON"
export const Invalid = "Key.Invalid"
export const InvalidEmailAddress = "Key.InvalidEmailAddress"
export const InvalidPasswordFormat = "Key.InvalidPasswordFormat"
export const Invoice = "Key.Invoice"
export const ItFlag = "Key.ItFlag"
export const ItLang = "Key.ItLang"
export const ItsEmptyInHere = "Key.ItsEmptyInHere"
export const JavaScript = "Key.JavaScript"
export const JoinTheArkApiNow = "Key.JoinTheArkApiNow"
export const Json = "Key.Json"
export const LargeDocumentOcr = "Key.LargeDocumentOcr"
export const LastBillingCycle = "Key.LastBillingCycle"
export const LeavePage = "Key.LeavePage"
export const Limitations = "Key.Limitations"
export const LimitationsDescription1 = "Key.LimitationsDescription1"
export const LimitationsDescription2 = "Key.LimitationsDescription2"
export const LinkSent = "Key.LinkSent"
export const LiveInterface = "Key.LiveInterface"
export const Login = "Key.Login"
export const LogoDeleteSuccessfully = "Key.LogoDeleteSuccessfully"
export const LogoUploadSuccessfully = "Key.LogoUploadSuccessfully"
export const ManageYourAccount = "Key.ManageYourAccount"
export const MaxFileSize = "Key.MaxFileSize"
export const MaxFileSizeWarning = "Key.MaxFileSizeWarning"
export const MembershipAgreement = "Key.MembershipAgreement"
export const Metadata = "Key.Metadata"
export const MetadataCleanImage = "Key.MetadataCleanImage"
export const MetadataCleanPdf = "Key.MetadataCleanPdf"
export const MetadataExtractImage = "Key.MetadataExtractImage"
export const MetadataExtractPdf = "Key.MetadataExtractPdf"
export const ModelCreatedSuccessfully = "Key.ModelCreatedSuccessfully"
export const ModelName = "Key.ModelName"
export const ModelTraining = "Key.ModelTraining"
export const ModelTrainingUploadErrorMessage = "Key.ModelTrainingUploadErrorMessage"
export const ModelTrainingUploadMessage = "Key.ModelTrainingUploadMessage"
export const ModelTrainingUploadWarningMessage = "Key.ModelTrainingUploadWarningMessage"
export const ModelType = "Key.ModelType"
export const ModerateAccuracy = "Key.ModerateAccuracy"
export const Month = "Key.Month"
export const Monthly = "Key.Monthly"
export const MoreInformation = "Key.MoreInformation"
export const MsExcel = "Key.MsExcel"
export const MustBeGreaterThanField = "Key.MustBeGreaterThanField"
export const MyCurrentPlan = "Key.MyCurrentPlan"
export const MyProfile = "Key.MyProfile"
export const NameSurname = "Key.NameSurname"
export const NameYourClass = "Key.NameYourClass"
export const NameYourModel = "Key.NameYourModel"
export const NewEmailAddress = "Key.NewEmailAddress"
export const NewField = "Key.NewField"
export const NewPassword = "Key.NewPassword"
export const NewPasswordAgain = "Key.NewPasswordAgain"
export const NewPasswordAgainPlaceholder = "Key.NewPasswordAgainPlaceholder"
export const NewPasswordPlaceholder = "Key.NewPasswordPlaceholder"
export const Next = "Key.Next"
export const No = "Key.No"
export const NoApiKeyMeetsCriteria = "Key.NoApiKeyMeetsCriteria"
export const NoCancel = "Key.NoCancel"
export const NoData = "Key.NoData"
export const NoDataMessage = "Key.NoDataMessage"
export const NoResult = "Key.NoResult"
export const NoResultDescription = "Key.NoResultDescription"
export const NoServiceSelected = "Key.NoServiceSelected"
export const NotApproveWithExpressConsent = "Key.NotApproveWithExpressConsent"
export const NumberOfAnnotatedDocuments = "Key.NumberOfAnnotatedDocuments"
export const NumberOfEntities = "Key.NumberOfEntities"
export const NumberOfPage = "Key.NumberOfPage"
export const Ocr = "Key.Ocr"
export const OcrImageInfo = "Key.OcrImageInfo"
export const OcrPdfInfo = "Key.OcrPdfInfo"
export const OcrTiffInfo = "Key.OcrTiffInfo"
export const OhhItsEmptyInHere = "Key.OhhItsEmptyInHere"
export const Ok = "Key.Ok"
export const OneLastStep = "Key.OneLastStep"
export const Operator = "Key.Operator"
export const OrganizationKeyCanCustomizeMessage = "Key.OrganizationKeyCanCustomizeMessage"
export const OurPricing = "Key.OurPricing"
export const Page = "Key.Page"
export const PageReadyToCreateYourFirstApiKey = "Key.PageReadyToCreateYourFirstApiKey"
export const Password = "Key.Password"
export const EmailChangedSuccessfully = "Key.EmailChangedSuccessfully"
export const PasswordChangedSuccessfully = "Key.PasswordChangedSuccessfully"
export const PasswordFormatMessage = "Key.PasswordFormatMessage"
export const PasswordResetMailSent = "Key.PasswordResetMailSent"
export const PasswordResetSuccessfully = "Key.PasswordResetSuccessfully"
export const PasswordsNotMatch = "Key.PasswordsNotMatch"
export const PayAsYouGo = "Key.PayAsYouGo"
export const Payment = "Key.Payment"
export const PaymentDetails = "Key.PaymentDetails"
export const PaymentSuccessful = "Key.PaymentSuccessful"
export const PdfCompress = "Key.PdfCompress"
export const PdfCompressDescription = "Key.PdfCompressDescription"
export const PdfDecrypt = "Key.PdfDecrypt"
export const PdfDecryptDescription = "Key.PdfDecryptDescription"
export const PdfEncrypt = "Key.PdfEncrypt"
export const PdfEncryptDescription = "Key.PdfEncryptDescription"
export const PdfExtractImage = "Key.PdfExtractImage"
export const PdfExtractImageDescription = "Key.PdfExtractImageDescription"
export const PdfInfo = "Key.PdfInfo"
export const PdfInfoDescription = "Key.PdfInfoDescription"
export const PdfInfoExtractFeedbackMessage = "Key.PdfInfoExtractFeedbackMessage"
export const PdfInfoNoResponseMessage = "Key.PdfInfoNoResponseMessage"
export const PdfInfoServiceInfoMessage = "Key.PdfInfoServiceInfoMessage"
export const PdfMerge = "Key.PdfMerge"
export const PdfMergeDescription = "Key.PdfMergeDescription"
export const PdfSplit = "Key.PdfSplit"
export const PdfSplitDescription = "Key.PdfSplitDescription"
export const PhoneNumber = "Key.PhoneNumber"
export const Plan = "Key.Plan"
export const PleaseFieldName = "Key.PleaseFieldName"
export const PleaseFillOutExpiryDate = "Key.PleaseFillOutExpiryDate"
export const Post = "Key.Post"
export const PreliminaryInformationForm = "Key.PreliminaryInformationForm"
export const Processing = "Key.Processing"
export const PurchaseMoreCredits = "Key.PurchaseMoreCredits"
export const Purchasing = "Key.Purchasing"
export const Python = "Key.Python"
export const QueryParameter = "Key.QueryParameter"
export const QueryParameterDescription = "Key.QueryParameterDescription"
export const Recommended = "Key.Recommended"
export const RecordCancelledSuccessfully = "Key.RecordCancelledSuccessfully"
export const RecordDeletedSuccessfully = "Key.RecordDeletedSuccessfully"
export const RecordSavedSuccessfully = "Key.RecordSavedSuccessfully"
export const RecordUpdatedSuccessfully = "Key.RecordUpdatedSuccessfully"
export const ReenterYourArkApiPassword = "Key.ReenterYourArkApiPassword"
export const RefreshKey = "Key.RefreshKey"
export const Regenerate = "Key.Regenerate"
export const RegenerateKey = "Key.RegenerateKey"
export const RegenerateKeyMessage = "Key.RegenerateKeyMessage"
export const RegenerateKeyMessageTitle = "Key.RegenerateKeyMessageTitle"
export const RegisterCompleteMessage = "Key.RegisterCompleteMessage"
export const Remaining = "Key.Remaining"
export const RequestBody = "Key.RequestBody"
export const RequestBodyDescription = "Key.RequestBodyDescription"
export const Required = "Key.Required"
export const ResendEmail = "Key.ResendEmail"
export const ResetLinkSentToMailAddress = "Key.ResetLinkSentToMailAddress"
export const ResetMyPassword = "Key.ResetMyPassword"
export const ResetPassword = "Key.ResetPassword"
export const ResetPasswordInvalidToken = "Key.ResetPasswordInvalidToken"
export const ResetYourPassword = "Key.ResetYourPassword"
export const ResponseScheme = "Key.ResponseScheme"
export const ResponseSchemeDescription = "Key.ResponseSchemeDescription"
export const ResponseStructure = "Key.ResponseStructure"
export const ReturnToSite = "Key.ReturnToSite"
export const RevokeApiKey = "Key.RevokeApiKey"
export const RevokeApiKeyMessage = "Key.RevokeApiKeyMessage"
export const RevokeApiKeyMessageTitle = "Key.RevokeApiKeyMessageTitle"
export const Rotation = "Key.Rotation"
export const RotationAngleCalculate = "Key.RotationAngleCalculate"
export const RotationAngleRotate = "Key.RotationAngleRotate"
export const RotationBoxesCalculate = "Key.RotationBoxesCalculate"
export const RotationBoxesDraw = "Key.RotationBoxesDraw"
export const SampleCode = "Key.SampleCode"
export const Save = "Key.Save"
export const SaveAndGoToModelPage = "Key.SaveAndGoToModelPage"
export const SaveAndSubmit = "Key.SaveAndSubmit"
export const SaveLogo = "Key.SaveLogo"
export const SavedAddress = "Key.SavedAddress"
export const SavedAddresses = "Key.SavedAddresses"
export const Search = "Key.Search"
export const SearchApiKey = "Key.SearchApiKey"
export const SearchApiKeyActive = "Key.SearchApiKeyActive"
export const SecurityCode = "Key.SecurityCode"
export const SeeSpendingsInDetail = "Key.SeeSpendingsInDetail"
export const SelectAddress = "Key.SelectAddress"
export const SelectAll = "Key.SelectAll"
export const SelectAnServiceFromBelowToSeeUsageTime = "Key.SelectAnServiceFromBelowToSeeUsageTime"
export const SelectAtLeastOneService = "Key.SelectAtLeastOneService"
export const SelectModel = "Key.SelectModel"
export const SelectServiceFromBelowToSeeStatisticsForThatService = "Key.SelectServiceFromBelowToSeeStatisticsForThatService"
export const SelectYourBaseModel = "Key.SelectYourBaseModel"
export const SelectYourModelType = "Key.SelectYourModelType"
export const SelectYourPlan = "Key.SelectYourPlan"
export const SelectedCount = "Key.SelectedCount"
export const SendMail = "Key.SendMail"
export const ServiceApiKeyNotCreatedYetMessage = "Key.ServiceApiKeyNotCreatedYetMessage"
export const ServiceCategories = "Key.ServiceCategories"
export const Services = "Key.Services"
export const ServicesAllowed = "Key.ServicesAllowed"
export const SetupFields = "Key.SetupFields"
export const SetupClasses = "Key.SetupClasses"
export const ShortDocumentOcr = "Key.ShortDocumentOcr"
export const ShowApiKey = "Key.ShowApiKey"
export const SignIn = "Key.SignIn"
export const SignInDescription = "Key.SignInDescription"
export const SignInWithApiKey = "Key.SignInWithApiKey"
export const SignOut = "Key.SignOut"
export const Signature = "Key.Signature"
export const SignatureCompare = "Key.SignatureCompare"
export const SignatureCrop = "Key.SignatureCrop"
export const SignatureExtractCoordinate = "Key.SignatureExtractCoordinate"
export const SomethingWentWrongConfirmation = "Key.SomethingWentWrongConfirmation"
export const SortBy = "Key.SortBy"
export const SpendingsByCategories = "Key.SpendingsByCategories"
export const SpendingsByTime = "Key.SpendingsByTime"
export const Statistics = "Key.Statistics"
export const Status = "Key.Status"
export const StatusChangedSuccesfully = "Key.StatusChangedSuccesfully"
export const Submit = "Key.Submit"
export const SuccessFulJSON = "Key.SuccessFulJSON"
export const SupportedDocuments = "Key.SupportedDocuments"
export const TaxNumber = "Key.TaxNumber"
export const TaxOffice = "Key.TaxOffice"
export const TestYourModel = "Key.TestYourModel"
export const TextInfo = "Key.TextInfo"
export const TextInfoDescription = "Key.TextInfoDescription"
export const ThisCardNumberIsNotValid = "Key.ThisCardNumberIsNotValid"
export const TiffInfo = "Key.TiffInfo"
export const TiffInfoDescription = "Key.TiffInfoDescription"
export const ToPurchaseMoreCredits = "Key.ToPurchaseMoreCredits"
export const Total = "Key.Total"
export const TotalCountPages = "Key.TotalCountPages"
export const TotalSpendings = "Key.TotalSpendings"
export const Tr = "Key.Tr"
export const TrFlag = "Key.TrFlag"
export const TrLang = "Key.TrLang"
export const TrainModel = "Key.TrainModel"
export const TrainNewModel = "Key.TrainNewModel"
export const TryAgain = "Key.TryAgain"
export const Undo = "Key.Undo"
export const Update = "Key.Update"
export const UpdateEmailMessage = "Key.UpdateEmailMessage"
export const Upload = "Key.Upload"
export const Url = "Key.Url"
export const UseItInteractively = "Key.UseItInteractively"
export const UseService = "Key.UseService"
export const ModelOcrUseServiceMessage = "Key.ModelOcrUseServiceMessage"
export const VerificationLinkSentMessage = "Key.VerificationLinkSentMessage"
export const VerifyYourEmailMessage = "Key.VerifyYourEmailMessage"
export const VerifyYourPassword = "Key.VerifyYourPassword"
export const VisitOur = "Key.VisitOur"
export const WantToMakeChanges = "Key.WantToMakeChanges"
export const Weekly = "Key.Weekly"
export const WelcomeToArkapi = "Key.WelcomeToArkapi"
export const Xml = "Key.Xml"
export const Yes = "Key.Yes"
export const YesDelete = "Key.YesDelete"
export const YouAnnotateAtLeast20DocumentsTrainNewModel = "Key.YouAnnotateAtLeast20DocumentsTrainNewModel"
export const YouCanCopyKeyMessage = "Key.YouCanCopyKeyMessage"
export const YouCanCreateClassificationModelMessage = "Key.YouCanCreateClassificationModelMessage"
export const YouCanCreateNewKeyMessage = "Key.YouCanCreateNewKeyMessage"
export const YouCanCreateNewModelMessage = "Key.YouCanCreateNewModelMessage"
export const YouCanSetNewPassword = "Key.YouCanSetNewPassword"
export const YouHaveNotSpentAnyCredit = "Key.YouHaveNotSpentAnyCredit"
export const YouHaventFinishedCreditPayment = "Key.YouHaventFinishedCreditPayment"
export const YourComment = "Key.YourComment"
export const YourPasswordHasBeenCreated = "Key.YourPasswordHasBeenCreated"
export const YourSelectedPlan = "Key.YourSelectedPlan"
export const YourStatisticWillBeReadySoon = "Key.YourStatisticWillBeReadySoon"
export const DocumentUploaded = "Key.DocumentUploaded"
export const GridView = "Key.GridView"
export const ListView = "Key.ListView"
export const DeleteTheClass = "Key.DeleteTheClass"
export const DoYouWantToDeleteTheClass = "Key.DoYouWantToDeleteTheClass"
export const IfYouDeleteClass = "Key.IfYouDeleteClass"
export const AddClassToStart = "Key.AddClassToStart"
export const YouNeedToCreateAClass = "Key.YouNeedToCreateAClass"
export const IconAddField = "Key.IconAddField"
export const Copy = "Key.Copy"
export const File = "Key.File"
export const CountFiles = "Key.CountFiles"
export const JsonDownloadText = "Key.JsonDownloadText"
export const ZipDownloadText = "Key.ZipDownloadText"
export const SplitIntoPageRanges = "Key.SplitIntoPageRanges"
export const SplitUseServiceMessage = "Key.SplitUseServiceMessage"
export const FixedRanges = "Key.FixedRanges"
export const CustomRanges = "Key.CustomRanges"
export const SelectRangeMode = "Key.SelectRangeMode"
export const RangeWithNumber = "Key.RangeWithNumber"
export const FromPage = "Key.FromPage"
export const To = "Key.To"
export const AddRange = "Key.AddRange"
export const FixedRangesSplitInfoMessage = "Key.FixedRangesSplitInfoMessage"
export const CustomRangesSplitInfoMessage = "Key.CustomRangesSplitInfoMessage"
export const OcrType = "Key.OcrType"
export const NumberOfClasses = "Key.NumberOfClasses"
export const NumberOfTotalDocuments = "Key.NumberOfTotalDocuments"
export const UploadDocuments = "Key.UploadDocuments"
export const UploadNewDocuments = "Key.UploadNewDocuments"
export const UploadedDocuments = "Key.UploadedDocuments"
export const View = "Key.View"
export const Classes = "Key.Classes"
export const ClassificationStartInfoMessage = "Key.ClassificationStartInfoMessage"
export const ClassificationResponseInfoMessage = "Key.ClassificationResponseInfoMessage"
export const CreditsRemained = "Key.CreditsRemained"
export const Edited = "Key.Edited"
export const CountDocumentsAreDisplayed = "Key.CountDocumentsAreDisplayed"
export const ChangeUploadView = "Key.ChangeUploadView"
export const AddAClassByTyping = "Key.AddAClassByTyping"
export const TrainDocumentsFromScratch = "Key.TrainDocumentsFromScratch"
export const UploadFilesToSelectedClass = "Key.UploadFilesToSelectedClass"
export const UploadMoreFilesToSelectedClass = "Key.UploadMoreFilesToSelectedClass"
export const SaveYourModelStartTraining = "Key.SaveYourModelStartTraining"
export const UploadFiles = "Key.UploadFiles"
export const AddAField = "Key.AddAField"
export const AddAFieldByTyping = "Key.AddAFieldByTyping"
export const ChooseCategory = "Key.ChooseCategory"
export const ChooseCategoryForField = "Key.ChooseCategoryForField"
export const AnnotateFields = "Key.AnnotateFields"
export const AnnotateFieldsOnDocument = "Key.AnnotateFieldsOnDocument"
export const SwitchBetweenDocuments = "Key.SwitchBetweenDocuments"
export const SwitchBetweenDocumentsHere = "Key.SwitchBetweenDocumentsHere"
export const YouCanUploadNewDocuments = "Key.YouCanUploadNewDocuments"
export const OnceYouConfirm = "Key.OnceYouConfirm"
export const IfYouSaveYourModel = "Key.IfYouSaveYourModel"
export const EnterCorrectValue = "Key.EnterCorrectValue"
export const IncorrectPassword = "Key.IncorrectPassword"
export const UseServiceMessage = "Key.UseServiceMessage"
export const MergeUseServiceMessage = "Key.MergeUseServiceMessage"
export const OcrUseServiceMessage = "Key.OcrUseServiceMessage"
export const ApproveFileSuccessfully = "Key.ApproveFileSuccessfully"
export const UploadLower = "Key.UploadLower"
export const YouCanUploadImage = "Key.YouCanUploadImage"
export const DragAndDropOr = "Key.DragAndDropOr"
export const ToStart = "Key.ToStart"
export const FilesUploadedSuccessfully = "Key.FilesUploadedSuccessfully"
export const FileUploadFailed = "Key.FileUploadFailed"
export const YourApiResponseWillBeReady = "Key.YourApiResponseWillBeReady"
export const Revoke = "Key.Revoke"
export const YourApiKeysWilBeReady = "Key.YourApiKeysWilBeReady"
export const CopyClientId = "Key.CopyClientId"
export const IHaveReadAndApprove = "Key.IHaveReadAndApprove"
export const YouNeedToAccept = "Key.YouNeedToAccept"
export const PreliminaryInformation = "Key.PreliminaryInformation"
export const YourModelsWillBeReady = "Key.YourModelsWillBeReady"
export const OrderNo = "Key.OrderNo"
export const SessionExpired = "Key.SessionExpired"
export const TrainStart = "Document.TrainStart"
export const CommonSave = "Common.Save"
export const CommonDelete = "Common.Delete"
export const CommonUpdate = "Common.Update"
export const KeyRegenerate = "Key.RegenerateSave"
export const KeyRevoke = "Key.RevokeSave"
export const ModelDeleteHeader = "Key.DeleteHeader"
export const CommonDeleteButton = "Common.DeleteButton"
export const ModelDeleteBody = "Key.ModelDeleteBody"
export const PageNotFound = "Common.PageNotFound"
export const SorryWeCouldntFindThisPage = "Common.SorryWeCouldntFindThisPage"
export const YourSessionHasExpired = "Common.YourSessionHasExpired"
export const PleaseSignInToContinue = "Common.PleaseSignInToContinue"
export const FileZeroByteMessage = "Common.FileZeroByteMessage"
export const FileExceedMaxCapMessage = "Common.FileExceedMaxCapMessage"
export const FileUploadBlackList = "Common.FileUploadBlackList"
export const FileMalwareExistMessage = "Common.FileMalwareExistMessage"
export const FileUploadFailedError = "Common.FileUploadFailedError"
export const FilesUploadingPleaseWait = "Common.FilesUploadingPleaseWait"
export const YourFilesWillBeReady = "Common.YourFilesWillBeReady"
export const EditFields = "Key.EditFields"
export const SeeDocuments = "Key.SeeDocuments"
export const DeleteModel = "Key.DeleteModel"
export const ContinueToAnnotate = "Key.ContinueToAnnotate"
export const ShowVersionHistory = "Key.ShowVersionHistory"
export const VersionHistory = "Key.VersionHistory"
export const RestoreThisVersion = "Key.RestoreThisVersion"
export const DownloadJsonFile = "Key.DownloadJsonFile"
export const JsonFileDownloaded = "Key.JsonFileDownloaded"
export const ModelWasRestoredToVersion = "Key.ModelWasRestoredToVersion"